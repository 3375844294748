import React, {
	Fragment,
	useState,
	useMemo,
	useCallback,
	useLayoutEffect,
	useRef,
} from "react";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "~/components/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Auth from "@aws-amplify/auth";

import { Input, Password } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button, Link } from "~/components/Base/BaseActions";

import { getErrorMessage } from "@utils/errors";
import { stringifyQuery } from "@utils/url";
import { INavigateFn } from "@utils/navigate";
import { useAuth } from "@api/auth";

const useStyles = makeStyles((theme) => ({
	notify: {
		...theme.typography.body1,
		backgroundColor: darken(theme.palette.background.paper, 0.1),
		color: theme.palette.primary.contrastText,
		padding: `${theme.shape.authFormGutter}px 0`,
		textAlign: "center",
		width: "100%",
		boxShadow: "none",
	},
}));

interface IForgotPassForm {
	email?: string;
	code?: string;
	navigate: INavigateFn;
	finishAuth?: VoidFunction;
}

export const ForgotPassForm: React.FC<IForgotPassForm> = ({
	email,
	code,
	navigate,
	finishAuth,
}) => {
	const [curEmail, updateEmail] = useState(email || "");
	const [resetCode, updateCode] = useState(code || "");
	const [newPassword, updatePassword] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const { signin } = useAuth();

	const firstMount = useRef(true);

	const styles = useStyles();

	const handleResetRequest = useCallback(async () => {
		try {
			setLoading(true);
			setError("");
			if (!resetCode) await Auth.forgotPassword(curEmail);
			const query = stringifyQuery({
				code: resetCode,
				email: curEmail,
			});
			navigate(`?${query}`, {
				saveState: true,
			});
			setSubmitted(!resetCode);
		} catch (err) {
			console.error(err);
			setError(getErrorMessage(err));
		} finally {
			setLoading(false);
		}
	}, [curEmail, navigate, resetCode]);

	const handleReset = useCallback(async () => {
		let signedIn = false;
		try {
			setLoading(true);
			setError("");
			await Auth.forgotPasswordSubmit(
				curEmail,
				resetCode || "",
				newPassword,
			);
			if (finishAuth) {
				await signin(curEmail, newPassword);
				finishAuth();
				signedIn = true;
			}
			// navigate("/login", {
			//     saveState: true,
			// });
		} catch (err) {
			console.error(err);
			setError(getErrorMessage(err));
		} finally {
			if (!signedIn) {
				setLoading(false);
			}
		}
	}, [curEmail, resetCode, newPassword, finishAuth, signin]);

	useLayoutEffect(() => {
		if (firstMount.current) {
			const useEmail = email || "";
			updateEmail(useEmail);
			if (useEmail.length > 0) {
				handleResetRequest();
			}
			firstMount.current = false;
		}
	}, [email, handleResetRequest]);

	const doReset = useMemo(
		() => email !== undefined && code !== undefined,
		[email, code],
	);

	const componentDetails = useMemo(() => {
		if (doReset) {
			return {
				title: "Reset Password",
				buttonLabel: "Reset Password",
				click: handleReset,
			};
		}
		return {
			title: "Forgot Password",
			buttonLabel: "Reset Password",
			click: handleResetRequest,
		};
	}, [doReset, handleResetRequest, handleReset]);

	return (
		<BaseForm title={componentDetails.title} error={error}>
			{doReset ? (
				<Fragment>
					<Input
						id={"input-code"}
						update={updateCode}
						value={resetCode}
						label={"Code"}
						placeholder={"Code"}
						autoComplete={"reset-code"}
						autoFocus={!code}
						disabled={!!code}
					/>
					<Password
						id={"input-password"}
						update={updatePassword}
						value={newPassword}
						label={"New Password"}
						placeholder={"Password"}
						autoComplete={"new-password"}
						autoFocus={!!code}
					/>
				</Fragment>
			) : (
				<Fragment>
					<Input
						id={"input-email"}
						update={updateEmail}
						value={curEmail}
						label={"Email"}
						placeholder={"Email"}
						autoComplete={"username"}
						autoFocus
					/>
				</Fragment>
			)}
			<Button
				submit
				onClick={componentDetails.click}
				label={componentDetails.buttonLabel}
				loading={loading}
			/>
			{submitted && (
				<Grid item xs={12}>
					<Typography className={styles.notify} component={Paper}>
						{"Please check your email for password reset link"}
					</Typography>
				</Grid>
			)}
			<Link to="/login">Go to Login</Link>
		</BaseForm>
	);
};
