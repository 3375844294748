import React, { Fragment, useMemo, useCallback, useState } from "react";

import { createPublicView, ViewComponent } from "~/model/view/public";
import PublicTemplate from "~/components/PublicTemplate";
import { ForgotPassForm } from "~/composite/forms";
import { Redirect } from "~/components/Redirect";

import { parseQuery } from "@utils/url";

const redirectTo = "/leads";

const ForgotPass: ViewComponent = ({ location, navigate }) => {
	const { code = undefined, email = undefined } = useMemo(
		() => parseQuery(location?.search || ""),
		[location?.search],
	);

	const [doRedir, setRedir] = useState(false);

	const finishAuth = useCallback(() => {
		setRedir(true);
	}, []);

	return (
		<Fragment>
			<PublicTemplate
				formComponent={
					<ForgotPassForm
						code={code !== undefined ? code.toString() : code}
						email={email !== undefined ? email.toString() : email}
						navigate={navigate}
						finishAuth={finishAuth}
					/>
				}
			/>
			{doRedir && <Redirect to={redirectTo} returnTo />}
		</Fragment>
	);
};

export default createPublicView(ForgotPass, {
	title: "Forgot Password",
	redirectTo,
});
