import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Auth from "@aws-amplify/auth";

import { useViewContext } from "~/state";
import { getErrorMessage } from "@utils/errors";
import { BaseForm } from "~/components/Base/BaseForm";
import { Password } from "~/components/Base/BaseInputs";
import { Button } from "~/components/Base/BaseActions";

const useStyles = makeStyles(() => ({
	form: {
		width: 440,
		padding: 0,
		boxShadow: "none",
	},
	button: {
		margin: "0 0 0 auto",
		width: 100,
	},
}));

interface IChangePassFormProps {
	title?: string;
}

interface IChangePassFormState {
	current: string;
	new: string;
}

type ChangePassStateHandlers = Record<
	keyof IChangePassFormState,
	(newVal: string) => void
>;

export const ChangePassForm: React.FC<IChangePassFormProps> = ({ title }) => {
	const styles = useStyles();
	const {
		actions: { closeModal },
	} = useViewContext();
	const [passInput, setPassInput] = useState<IChangePassFormState>({
		current: "",
		new: "",
	});
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const handlers = useMemo<ChangePassStateHandlers>(() => {
		return Object.keys(passInput).reduce(
			(acc, key) => ({
				...acc,
				[key as keyof IChangePassFormState]: (newVal: string) =>
					setPassInput((curState) => ({
						...curState,
						[key]: newVal,
					})),
			}),
			{} as ChangePassStateHandlers,
		);
	}, [setPassInput, passInput]);

	const handleSubmit = async () => {
		let success = false;
		try {
			setLoading(true);
			const user = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(user, passInput.current, passInput.new);
			success = true;
			closeModal();
		} catch (err) {
			console.log(err);
			setError(getErrorMessage(err));
		} finally {
			if (!success) setLoading(false);
		}
	};

	return (
		<BaseForm title={title} className={styles.form} error={error}>
			<Password
				label="Current Password"
				placeholder="Current Password"
				autoComplete="current-password"
				update={handlers.current}
			/>
			<Password
				label="New Password"
				placeholder="New Password"
				autoComplete="new-password"
				update={handlers.new}
			/>
			<Button
				className={styles.button}
				grid={{
					xs: 12,
				}}
				label="Save"
				onClick={handleSubmit}
				loading={loading}
			/>
		</BaseForm>
	);
};
